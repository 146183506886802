<template>
  <div class="app-container">

    <div class="grid-content bg-purple no_bor_input filter-container">
      <el-button @click="handleCreate()" type="primary" size="small" plain>新增成员</el-button>
      <el-select v-model="listQuery.userRoleName" placeholder="请选择角色" size="small" style="width:120px" clearable filterable @change="handleFilter">
        <el-option v-for="item in userOption" :key="item.id" :label="item.name" :value="item.name">
        </el-option>
      </el-select>

      <el-input style="width:120px" v-model="listQuery.cnName" placeholder="请输入姓名" size="small"></el-input>
      <el-button class="filter-item" type="primary" @click="handleFilter" size="small" plain>搜索</el-button>
    </div>

    <el-table ref="table" :height="contentStyleObj" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" v-loading="listLoading" :key="tableKey" :data="list" border fit highlight-current-row style="width: 100%;">
      <!-- <el-table-column type="selection" align="center"/> -->
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column :label="序号" prop="id" align="center" width="55" type="index"></el-table-column>
      <el-table-column label="姓名" align="center" min-width="120">
        <template #default="scope">
          <span>{{ scope.row.cnName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户名（手机号）" align="center" width="150">
        <template #default="scope">
          <span>{{ scope.row.userName }}</span>
        </template>
      </el-table-column>
      
      <el-table-column label="手机号" align="center" width="150">
        <template #default="scope">
          <span>{{ scope.row.mobile }}</span>
        </template>
      </el-table-column>

      <el-table-column label="角色" align="center" width="180">
        <template #default="scope">
          <span>{{ scope.row.userRoleName }}</span>
        </template>
      </el-table-column>

      <el-table-column label="别名" align="center" min-width="270">
        <template #default="scope">
          <div>
            <el-input placeholder="" v-model="scope.row.remark" class="filter-item" size="small" style="width: 200px;height: 24px;" />
            <el-button type="success" size="small" @click="aliasSave(scope.row)" plain>保存</el-button>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="280" class-name="small-padding fixed-width">
        <template #default="scope">
          <el-button type="primary" @click="handleUpdate(scope.row)" size="small" plain v-if="scope.row.userRoleName != '学校管理员'">编辑</el-button>
          <!-- <el-button type="primary" @click="saverole(scope.row)" size="small" plain>角色管理</el-button> -->
          <el-button size="small" @click="del(scope.row)" type="danger" plain v-if="scope.row.userRoleName != '学校管理员'">删除</el-button>

          <el-button v-if="scope.row.status == 1 && scope.row.userRoleName != '学校管理员'" size="small" @click="saveStatus(scope.row, 2)" type="danger" plain >停用</el-button>
          <el-button v-else-if="scope.row.status != 1 && scope.row.userRoleName != '学校管理员'" size="small" @click="saveStatus(scope.row, 1)" plain>启用</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 弹框 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close :title="textMap[dialogStatus]" v-model="dialogFormVisible"  class="button_bg">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="120px">
        <div class="common_dialog_box clearfix">
          <div class="item_one clearfix">
            <el-form-item label="角色">
              <el-select v-model="temp.userRoleName" placeholder="请选择角色" size="small" style="width:150px">
                <el-option v-for="item in userOption" :key="item.id" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="班级" prop="dept" v-if="temp.userRoleName == '学生'">
              <el-cascader
                style="margin-left:5px;width:150px"
                size="small"
                :props="props"
                v-model="tempValue"
                :options="options"
                @change="handleChange"
                clearable></el-cascader>
            </el-form-item>

            <el-form-item label="姓名" prop="cnName">
              <el-input v-model="temp.cnName" placeholder="请输入内容" style="width:150px;margin-left: 5px;" size="small" ></el-input>
            </el-form-item>
            
            <el-form-item v-if="dialogStatus != 'create'" label="修改账号" prop="cnName">
              <el-radio-group v-model="temp.type">
                <el-radio :label="1">不修改</el-radio>
                <el-radio :label="2">修改</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="手机号" prop="userName">
              <el-input :disabled="temp.type == 1" v-model="temp.userName" placeholder="请输入手机号码" style="width:150px;margin-left: 5px;" size="small" ></el-input>
            </el-form-item>
            <el-form-item v-if="dialogStatus == 'create' || temp.type == 2" label="验证码" prop="yzm">
              <el-input v-model="temp.yzm" placeholder="请输入验证码" style="width:164px;margin-left: 5px;" size="small" ></el-input>
              <el-button size="small" v-if="count" style="width:82px;">{{count}}</el-button>
              <el-button size="small" v-else @click="getYzm" style="width:82px;">{{text}}</el-button>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
          <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()" size="small">确定</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 弹框 -->

    <!-- 角色弹框 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="修改" v-model="dialogFormVisiblerole"  class="button_bg">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="70px" style="width: 700px; margin:0 auto">

        <!-- <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox> -->
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange" size="small">
          <el-checkbox v-for="city in cities" :label="city.id" :key="city.id">{{city.name}}</el-checkbox>
        </el-checkbox-group>
        
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisiblerole = false" size="small">取消</el-button>
          <el-button type="primary" @click="updaterules()" size="small">确定</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 角色弹框 -->

    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
    
  </div>
</template>

<script>
import { getBaseDepts,getUsers,getSysRoles,saveUser,DeleteStudentFromClass,userBindingRole,saveUserStatus } from '@/api/sys.js'
import { sendSms } from '@/api/login.js'

export default {
  name: 'user-user',
  data() {
    return {
      pass4: true,
      props: {
        label: 'label',
        value: 'id',
      },
      checkAll: false,
      checkedCities: [],
      cities: ['上海', '北京', '广州', '深圳'],
      isIndeterminate: true,
      selectedOptions: [],
      options: [],
      list: [],
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '修改',
        create: '新增'
      },
      listQuery: {
        page: 1,
        limit: 20,
        deptId: 0,
        userRoleName:''
      },
      rules: {
        cnName: [{ required: true, message: '姓名不能为空', trigger: 'change' }],
        yzm: [{ required: true, message: '验证码不能为空', trigger: 'change' }],
        userName: [{ required: true, message: '请输入用户名', trigger: 'change' },{ min: 11, max: 11, message: '请输入正确手机号', trigger: 'blur' }],
        dept: [{ required: true, message: '请选择班级', trigger: 'change' }],
        password: [{ required: true, message: '请输入密码', trigger: 'change' }],
      },
      dialogFormVisiblerole: false,
      dialogPvVisible: false,
      temp: {
        type: 1,
        yzm: '',
        cnName: '',
        userName: '',
        dep: '',
        dept:'',
        deptId:0,
        mobile: '',
        password: '',
        userRoleName:''
        // isLeader: false,
      },
      contentStyleObj:{}, //高度变化
      text: "获取验证码",
      count: 0,
      userOption:[
      {
          label:'学生',
          value:'学生'
        },
        {
          label:'老师',
          value:'老师'
        },
        {
          label:'全部',
          value:''
        }
      ],
      userOption2:[
        {
          label:'学生',
          value:'学生'
        },
        {
          label:'老师',
          value:'老师'
        }
      ],
      tempValue:[]
    }
  },
  created() {
    // 获取表格高度
    this.contentStyleObj=this.$getHeight(255)
    window.addEventListener('resize', this.getHeight);
    this.init()
    this.getList()
    this.initBus()
  },
  methods:{
    initBus(){
      this.$bus.off("addUpdate")
      this.$bus.on("addUpdate", (val) => {
        this.init()
      });
    },
    saveStatus(row, status){
      saveUserStatus({id: row.id, status: status}).then(res=>{
        if(res.data.msg == "success"){
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.getList()
        }
      })
    },
    getCode(){
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          this.count--;
          } else {
          this.show = true;
          clearInterval(this.timer);
          this.timer = null;
          }
        }, 1000)
      }
    },
    getYzm(){
      if(!(/^1[3456789]\d{9}$/.test(this.temp.userName))){
        this.$message({
          type: 'info',
          message: '请输入正确手机号'
        }); 
        return
      }
      this.getCode()
      sendSms({"mobile": this.temp.userName}).then(res=>{
        if(res.data.msg != "success"){
          return
        }
        this.$message({
          message: '发送成功',
          type: 'success'
        })
      })
    },
    savepass4(e){
      e.password = ""
      this.pass4 = !this.pass4
    },
    del(row) {
      this.$confirm('确认删除该用户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {}
        if(row.userRoleName == '老师'){
          param = {
            teacher_id:row.id
          }
        }else{
          param = {
            student_id:[row.id],
            deptId:row.deptId
          }
        }
        DeleteStudentFromClass(param).then(res => {
          if(res.data.msg == "success"){
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.$bus.emit('personUpdate')
            this.getList()
          }    
        })
      })
    },
    handleFilter() {
      if(this.listQuery.dept){
        this.listQuery.deptId = this.listQuery.dept
      }else{
        this.listQuery.deptId = 0
      }
      this.listQuery.page = 1
      this.getList()
    },
    getList() {
      this.listLoading = true
      getUsers(this.listQuery).then(response => {
        this.list = response.data.data.list
        this.total = response.data.data.total
        this.listLoading = false
      })
    },
    updaterules() {
      if(this.checkedCities.length == 0){
        this.$message({
          type: 'info',
          message: '请选择角色'
        }); 
        return false
      }
      let arr = this.checkedCities.map(v=>{
        return {userId:this.temp.id*1,roleId:v*1}
      })
      userBindingRole(arr).then(res=>{
        if(res.data.msg == "success"){
          this.$message({
            message: '绑定成功',
            type: 'success'
          })
          this.getList();
          this.dialogFormVisiblerole = false
        }
      })
    },
    handleCheckAllChange(val) {
      let cic = this.cities.map(v=>{
        return v.id
      })
      this.checkedCities = val ? cic : [];
      this.isIndeterminate = false;
    },
    init() {
      this.listLoading = false
      getBaseDepts({}).then(res => {
        let big = res.data.data.list[0].children
        this.options = big
      })
      getSysRoles({}).then(response => {
        this.userOption = response.data.data.list
      })
    },
    resetTemp() {
      this.temp = {
        type: 2,
        cnName: '',
        userName: '',
        dep: [],
        mobile: '',
        password: '',
        // isLeader: false,
      }
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          if(this.temp.dept){
            this.temp.deptId = this.temp.dept
          }else{
            this.temp.deptId = 0
          }
          if(this.temp.userRoleName == '老师'){
            this.temp.dept = ''
            this.temp.deptId = 0
          }
          this.$confirm('我们将以短信的形式把账号和随机密码发送至您的手机上, 请确认账号是否输入正确?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            saveUser(this.temp).then(res => {
              if(res.data.msg != "success"){
                return
              }
              this.dialogFormVisible = false
              this.$notify({
                title: '成功',
                message: '创建成功',
                type: 'success',
                duration: 2000
              })
              this.$bus.emit('personUpdate')
              this.getList()
            })
          })
        }
      })
    },
    handleUpdate(row) {
      this.temp = Object.assign({}, row) // copy obj
      if(this.temp.deptId){
        this.temp.dept = this.temp.deptId
      }else{
        this.temp.dept = ''
      }
      this.temp.type = 1
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
      this.tempValue = this.changeDetSelect(row.deptId,this.options)
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          if(tempData.dept){
            tempData.deptId = tempData.dept
          }else{
            tempData.deptId = 0
          }
          if(tempData.userRoleName == '老师'){
            tempData.dept = ''
            tempData.deptId = 0
          }
          saveUser(tempData).then(res => {
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.$bus.emit('personUpdate')
              this.getList()
              this.dialogFormVisible = false
            }
          })
        }
      })
    },
    saverole(row) {
      this.temp = Object.assign({}, row) // copy obj
      this.checkedCities = this.checkedCities.map(v=>{
        return Number(v)
      })
      this.dialogFormVisiblerole = true
    },
    handleChange(val){
      if(this.tempValue){
        this.temp.dept = this.tempValue[this.tempValue.length-1]
        this.temp.deptId = this.tempValue[this.tempValue.length-1]
      }
    },
    // 班级回显
    changeDetSelect(key, treeData) {
      console.log(key,treeData);
      let arr = []; // 在递归时操作的数组
      let returnArr = []; // 存放结果的数组
      let depth = 0; // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN; // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id;
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
            break;
          } else {
            if (childrenData[j].children) {
              depth++;
              childrenEach(childrenData[j].children, depth);
            }
          }
        }
        return returnArr;
      }
      return childrenEach(treeData, depth);
    },
  },

}
</script>

<style>
.no_bor_input .el-input__inner{
  border-radius: 0;
}
</style>

<style lang="scss" scoped>
.el-checkbox{
  width: 160px !important;
  margin-right: 20px;
  margin-bottom: 8px;
}
.el-checkbox:nth-child(4n-1){
  margin-right: 0;
}
.el-checkbox+.el-checkbox{
  margin-left: 0;
}
.xingxing{
  position: relative;
    .savepassi{
      position: absolute;
      right: 20px;
      top: 2px;
      font-size: 16px;
      width: 16px;
    }
    background: #fff6f7;
    height: 28px;
    vertical-align: top;
    line-height: 28px;
    text-indent: 1em;
    border: 1px solid #c2c2c2;
    width: 353px;
    display: inline-block;
    box-sizing: border-box;
    margin-bottom: 10px;
    i{
      margin-top: 3px;
      color: #fe6c6f;
    }
}
</style>
